import signupFormService from '../../services/database/signupForm'
import lineFlexMessage from '../../services/database/lineFlexMessage'

const signupFormModule = {
  namespaced: true,
  state: {
    docId: '',
    brideName: '',
    groomName: '',
    promptpay: {
      active: '',
      namePromptpay: '',
      numberPromptpay: '',
      lineNotifyWish: []
    },
    eventInfo: {
      mode: '', //morning, evening, morningAndEvening
      morning: {
        title: 'งานเช้า',
        location: {
          name: '',
          latitude: 0,
          longtitude: 0
        },
        floor: '',
        room: '',
        date: new Date(),
        time: String(new Date().getHours()) + ':' + String(new Date().getMinutes()),
        theme: '',
        food: ''
      },
      evening: {
        title: 'งานเย็น',
        location: {
          name: 'qwe',
          latitude: 0,
          longtitude: 0
        },
        floor: '',
        room: '',
        date: new Date(),
        time: String(new Date().getHours()) + ':' + String(new Date().getMinutes()),
        theme: '',
        food: ''
      }
    },
    guestGroup: {
      bride: [],
      groom: [],
    },
    thanksPage: {
      imageUrl: '',
      text: 'กรุณาข้อความ',
      textColor: '#fff',
      weight: '500',
      fontSize: 20,
      align: 'center',
      vertical: '50%'
    },
    welcomePageImages: ['', '', '', ''],
    frontImageCard: '',
    backImageCard: '',
  },
  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    }
  },
  actions: {
    init({ dispatch }) {
      dispatch('fetchSignupForm')
      dispatch('fetchLineFlexMessage')
    },
    async fetchSignupForm({ commit, rootState }) {
      const { eventId } = rootState.appState
      const docId = eventId
      const doc = await signupFormService()._getByDocId(docId)

      const {
        brideName,
        groomName,
        welcomePageImages,
        eventInfo,
        guestGroup,
        thanksPage,
        promptpay,
      } = doc.data()

      commit('setState', { key: 'docId', value: doc.id })
      commit('setState', { key: 'welcomePageImages', value: welcomePageImages })
      commit('setState', { key: 'eventInfo', value: eventInfo })
      commit('setState', { key: 'guestGroup', value: guestGroup })
      commit('setState', { key: 'thanksPage', value: thanksPage })
      commit('setState', { key: 'brideName', value: brideName })
      commit('setState', { key: 'groomName', value: groomName })
      commit('setState', { key: 'promptpay', value: promptpay })
    },
    async fetchLineFlexMessage({commit, rootState}) {
      const { eventId } = rootState.appState
      const docId = eventId
      const doc = await lineFlexMessage()._getByDocId(docId)
      
      const { frontImageCard, backImageCard } = doc.data()

      commit('setState', { key: 'frontImageCard', value: frontImageCard })
      commit('setState', { key: 'backImageCard', value: backImageCard })
    },
  },
  getters: {}
}

export default signupFormModule
