import { storage } from '../../config/firebaseInstance'
import { delay } from '../../utility/functions/delay'

const paymentStorage = (eventId) => {
  const storageRef = storage.ref(eventId)

  const upload = async (fileName, file) => {
    const ref = storageRef.child(fileName)
    const result = await ref.put(file)
    const { fullPath } = result._delegate.metadata
    const [name, fileExtension] = fullPath.split('.')
    const _hdImagePath = `${name}_hd.${fileExtension}`
    await delay(1000 * 7)
    const url = await storage.ref(_hdImagePath).getDownloadURL()
    return url
  }

  return {
    upload
  }
}

export default paymentStorage