import { lineFlexMessage } from '../../config/firebaseInstance'

const guestsService = () => {
  const _getByDocId = (docId) => {
    return lineFlexMessage.doc(docId).get()
  }

  return {
    _getByDocId
  }
}

export default guestsService