import liff from '@line/liff'
import lineLoginApi from '../../services/APIs/lineLogin'

const CLIENT_ID = '1654864455'
const SECRET_ID = 'be47e65da11fb1d9399038d36b6c3d47'
const LIFF_ID = '1654864455-eZgZqOKY'

const appStateModule = {
  namespaced: true,
  state: {
    lineProfile: {
      userId: '',
      displayName: '',
      pictureUrl: '',
      statusMessage: ''
    },
    eventId: '',
    isLoading: false
  },
  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    },
    toggleIsloading(state) {
      state.isLoading = !state.isLoading
    }
  },
  actions: {
    async init({ dispatch }, { eventId, code }) {
      await dispatch('setEventId', eventId)
      await dispatch('liffInit', code)
      dispatch('signupForm/init', null, { root: true })
      dispatch('guest/init', null, { root: true })
    },

    async liffInit({ dispatch }, code) {
      await liff.init({ liffId: LIFF_ID })
      const redirectUrl = window.location.origin + window.location.pathname
      const urlEncode = encodeURIComponent(redirectUrl)

      if (liff.isInClient()) {
        await liff.ready
        await dispatch('getLiffProfile')
        return null
      }

      if (code) {
        const { data } = await lineLoginApi().getExternalLineToken({
          code,
          redirectUrl: redirectUrl,
          clientId: CLIENT_ID,
          clientSecret: SECRET_ID
        })
        await dispatch('getExternalLineProfile', data.access_token)
        return null
      }
      window.location.href = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${urlEncode}&state=null&scope=profile%20openid`
    },

    async getLiffProfile ({ commit }) {
      const lineProfile = await liff.getProfile()
      commit('setState', { key: 'lineProfile', value: lineProfile })
      return null
    },

    async getExternalLineProfile ({ commit }, accessToken) {
      const { data: lineProfile } = await lineLoginApi().getExternalProfile(accessToken)
      commit('setState', { key: 'lineProfile', value: lineProfile })
      return null
    },

    setEventId ({ commit }, eventId) {
      const storageEventId = window.localStorage.getItem('eventId')

      if(eventId) {
        commit('setState', { key: 'eventId', value: eventId })
        window.localStorage.setItem('eventId', eventId)
        return
      }

      if(storageEventId) {
        commit('setState', { key: 'eventId', value: storageEventId })
        return
      }
    }
  },
  getters: {}
}

export default appStateModule