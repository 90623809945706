import { payment } from './enpoints'
import axios from 'axios'

const paymentAPIs = () => {
  const verifySlip = (imageFile) => {
    const path = '/api/slipVerify/image'
    const fullEnpoint = `${payment}${path}`
    const headerToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiJucy13ZWRkaW5nLXdpc2giLCJyb2xlIjoibmljZWxvb3Atc2VydmljZSJ9.117HyTMaX6uFHJA9RSo-HA06nIPP6NdufUN10XtOXoc'
    let bodyFormData = new FormData()
    bodyFormData.append('slip', imageFile)
  
    return axios({
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${headerToken}`
      },
      url: fullEnpoint,
      data: bodyFormData
    })
  }

  return {
    verifySlip
  }
}

export default paymentAPIs