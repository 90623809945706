import guestService from '../../services/database/guests'
import guestStorage from '../../services/storage/guest'
import paymentStorage from '../../services/storage/payment'
import paymentAPIs from '../../services/APIs/payment'
import _ from 'lodash'

import router from '../../router/index'

const appStateModule = {
  namespaced: true,
  state: {
    morning: {
      numberOfGuest: 0,
      attend: false,
    },
    evening: {
      numberOfGuest: 0,
      attend: false
    },
    groupOf: '',
    guestOf: '',
    fullName: '',
    firstName: '',
    lastName: '',
    nameGuest: '',
    tel: '',
    address: '',
    attend: true,
    wish: '',
    wishOpened: false,
    payment: {
      active: false,
      slipImageUrl: '',
      verified: false,
      timestamp: '',
      amount: ''
    }
  },
  mutations: {
    setState(state, { key, value }) {
      state[key] = value
    },
  },
  actions: {
    init ({ dispatch }) {
      dispatch('fetchGuestOnCloud')
    },

    async saveToCloud({ rootState, state }) {
      const { eventId, lineProfile } = rootState.appState
      const userId = lineProfile.userId
      const docId = `${eventId}:${userId}`
      const payload = {
        ...state,
        eventId,
        lineProfile,
        createAt: new Date().toISOString()
      }
      if (!state.morning.attend) delete payload.morning
      if (!state.evening.attend) delete payload.evening
      await guestService()._set(docId, payload)
      window.localStorage.removeItem('form2')
    },

    async updateState({ commit, state, rootState }, { key, value }) {
      commit('setState', { key, value })
      let cloneState = _.cloneDeep(state)
      delete cloneState.morning
      delete cloneState.evening
      delete cloneState.groupOf
      delete cloneState.guestOf
      const jsonString = JSON.stringify({ ...cloneState, userId: rootState.appState.lineProfile.userId })
      window.localStorage.setItem('form2', jsonString)
    },

    async fetchGuestOnCloud ({ commit, rootState, dispatch }) {
      const { eventId, lineProfile } = rootState.appState
      const docId = `${eventId}:${lineProfile.userId}`
      const doc = await guestService()._getByDocId(docId)

      if(doc.exists) {
        const data = doc.data()
        for(const key in data) {
          commit('setState', { key: key, value: data[key] })
        }

        router.push({ path: '/thanks' })
      } else {
        dispatch('getGuestInLocalStorage')
      }
    },

    getGuestInLocalStorage ({ commit, rootState }) {
      const fromJsonString = window.localStorage.getItem('form2')
      if(!fromJsonString) return
      const form = JSON.parse(fromJsonString)
      if(form.userId != rootState.appState.lineProfile.userId) return
      for (const key in form) {
        commit('setState', { key: key, value: form[key] })
      }
    },

    async uploadSlip ({ commit, rootState, state }, { file }) {
      const { lineProfile, eventId } = rootState.appState

      const { data: responseData } = await paymentAPIs().verifySlip(file)

      const fileName = `${lineProfile.userId}.jpg`
      const url = await paymentStorage(eventId).upload(fileName, file)

      const data = {
        ...state.payment,
        slipImageUrl: url,
        verified: responseData.verified,
        amount: responseData.data.amount,
        timestamp: new Date().toISOString()
      }
      commit('setState', { key: 'payment', value: data })

      const jsonString = JSON.stringify({ ...state, userId: lineProfile.userId })
      window.localStorage.setItem('form2', jsonString)
      return ''
    },

    async uploadprofileImage ({ commit, rootState, state }, { file }) {
      const { lineProfile, eventId } = rootState.appState

      const fileName = `${lineProfile.userId}.jpg`
      const url = await guestStorage(eventId).upload(fileName, file)

      commit('setState', { key: 'profileImage', value: url })

      const jsonString = JSON.stringify({ ...state, userId: lineProfile.userId })
      window.localStorage.setItem('form2', jsonString)
      return ''
    }
  },
  getters: {}
}

export default appStateModule