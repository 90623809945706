import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyDdbsVpyYUaJtSleMWLk1XfaTNk4xvYDZg",
  authDomain: "wedding-invitation-dca8f.firebaseapp.com",
  projectId: "wedding-invitation-dca8f",
  storageBucket: "wedding-invitation-dca8f.appspot.com",
  messagingSenderId: "934780550555",
  appId: "1:934780550555:web:8aaf9910e017aa329ef53e"
}

firebase.initializeApp(firebaseConfig)

const db = firebase.firestore()
const guestsRef = db.collection('guests')
const signupForm = db.collection('signupForm')
const lineFlexMessage = db.collection('lineFlexMessage')

const storage = firebase.storage()

export {
  db,
  guestsRef,
  signupForm,
  lineFlexMessage,
  storage
}