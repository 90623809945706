<template>
  <div id="app">
    <loading />

    <div class="contents">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Loading from './components/Loading.vue'

export default {
  components: {
    Loading
  }
}
</script>