import { createStore } from 'vuex'
import appState from './modules/appState'
import guest from './modules/guest'
import signupForm from './modules/signupForm'

const store = createStore({
  modules: {
    appState,
    guest,
    signupForm
  },
  state: {},
  mutations: {},
  actions: {}
})

export default store
