import { signupForm } from '../../config/firebaseInstance'

const signupFormService = () => {
  const _set = (docId, payload) => {
    return signupForm.doc(docId).set(payload)
  }

  const _update = (docId, payload) => {
    return signupForm.doc(docId).update(payload)
  }

  const _updateField = (docId, key, value) => {
    return signupForm.doc(docId).update({ key, value })
  }

  const _delete = (docId) => {
    return signupForm.doc(docId).delete()
  }

  const _getByDocId = (docId) => {
    return signupForm.doc(docId).get()
  }

  return {
    _set,
    _update,
    _updateField,
    _delete,
    _getByDocId
  }
}

export default signupFormService