import { storage } from '../../config/firebaseInstance'

const paymentStorage = (eventId) => {
  const storageRef = storage.ref(eventId)

  const upload = async (fileName, file) => {
    const ref = storageRef.child(`slip/${fileName}`)
    await ref.put(file)
    const url = await ref.getDownloadURL()
    return url
  }

  return {
    upload
  }
}

export default paymentStorage