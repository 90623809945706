import axios from 'axios'
import { line } from './enpoints'

const lineLogin = () => {
  const baseEnpoint = line

  const getExternalLineToken = (data) => {
    const fullEnpoint = `${baseEnpoint}/oauth2/v2.1/token`
    const {
      code,
      redirectUrl,
      clientId,
      clientSecret
    } = data

    const params = new URLSearchParams()
    params.append('grant_type', 'authorization_code')
    params.append('code', code)
    params.append('redirect_uri', redirectUrl)
    params.append('client_id', clientId)
    params.append('client_secret', clientSecret)

    return axios.post(fullEnpoint, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  }

  const getExternalProfile = (accessToken) => {
    const fullEnpoint = `${baseEnpoint}/v2/profile`
    return axios.get(fullEnpoint, {
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
  }

  return {
    getExternalLineToken,
    getExternalProfile
  }
}

export default lineLogin