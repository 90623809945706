import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/event-not-found',
    name: 'EventNotFound',
    component: () => import('../views/event-not-found.vue')
  },
  {
    path: '/welcome',
    name: 'Home',
    component: () => import('../views/welcome-page.vue')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/thanks-page.vue')
  },
  {
    path: '/form',
    name: 'SteperForm',
    component: () => import('../views/stepRegister/index.vue')
  },
  {
    path: '/not-attend',
    name: 'NotAttend',
    component: () => import('../views/stepNotAttend/index.vue')
  },

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
